// https://bit.dev/amazingdesign/react-redux-mui-starter/i18n

import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

const createI18nInstance = (i18nOptions) => {
  const i18n = i18next.createInstance()

  i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      detector: detectorOptions,
      interpolation: {
        escapeValue: false,
      },
      // use keys as fallback
      fallbackLng: false,
      backend: {
        // eslint-disable-next-line no-undef
        loadPath: `${process.env.PUBLIC_URL || ''}/locales/{{lng}}.json`,
      },
      defaultNS: 'translation',
      ns: ['translation'],
      ...i18nOptions,
    })

  return i18n
}

const detectorOptions = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'localStorage',
    'cookie',
    'path',
    'subdomain',
    'htmlTag',
    'navigator',
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],

  // languages to not persist (cookie, localStorage)
  excludeCacheFor: ['cimode'],

  // optional htmlTag with lang attribute, the default is:
  htmlTag: typeof window !== 'undefined' && window.document && window.document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true,
}

export const i18n = createI18nInstance({
  keySeparator: false,
})

window.i18n = i18n

export default i18n